
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'Inter','Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0; /* Make sure to include padding here */
  box-sizing: border-box;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#navbar{
  display:flex;
  align-items:center;
  justify-content:right;
  width:412px;
  height:38px;
  gap:8px;
}

.list_items{
  list-style:none;
  position:relative;
}

@media screen and (max-width:500px){
  #navbar{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    position:fixed;
    top: 58px; 
    right:-300px;
    width:300px;
    height:100vh;
    background-color:black;
    box-shadow:0 40px 60px rgba(0,0,0,0.1);
    padding:30px 10px 0 10px;
  
   
    
  }
  #navbar.active{
    right:0px;
  }
  
  
  
  }

@media screen and (min-width: 500px) and (max-width: 850px){
#navbar{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  position:fixed;
  top: 84px; 
  right:-300px;
  width:300px;
  height:100vh;
  background-color:black;
  box-shadow:0 40px 60px rgba(0,0,0,0.1);
  padding:30px 10px 0 10px;

 
  
}
#navbar.active{
  right:0px;
}



}
